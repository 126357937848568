.arrowBounce {
    animation: bouncing 1s infinite;
  }
  
  @keyframes bouncing {
    0%,
    100% {
      transform: translateY(-25%) translateX(-50%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0) translateX(-50%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  